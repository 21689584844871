import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { PageSource } from '@mindfulchefuk/analytics'

export const usePageSource = (url?: string): PageSource => {
  const router = useRouter()

  const path = url || router.asPath || router.pathname

  return useMemo(() => {
    if (!router.isReady) {
      return
    }
    const hasDeliveryDate = router.query?.deliveryDate
      ? router.query?.deliveryDate.length > 0
      : false

    switch (true) {
      case path.includes('sign-in'):
        return 'login page'
      case path.includes('/checkout/success'):
        return 'recipe onboarding success'
      case path.includes('/success'):
        return 'confirmation'
      case path.includes('/choose-recipes'):
        return 'choose recipes page'
      case path.includes('/my-account/recipes'):
        return 'your recipes'
      case path.includes('/my-account/orders/calendar'):
        return 'calendar'
      case path.includes('/my-account/orders/upcoming'):
        return 'upcoming orders'
      case path.includes('/my-account/orders/past'):
        return 'past orders'
      case path.includes('/my-account/delivery/[deliveryDate]/details'):
        return 'order details page'
      case path.startsWith('/my-account/delivery') && hasDeliveryDate:
        if (router.query) {
          switch (router.query.page) {
            case 'meals':
              return 'meals page'
            case 'smoothie':
              return 'smoothies page'
            case 'breakfasts':
              return 'breakfasts page'
            case 'snacks':
              return 'snacks page'
            case 'frozen_desserts':
              return 'frozen desserts page'
            case 'bundled_products':
              return 'bundles page'
            case 'broths_and_soups':
              return 'broths and soups page'
            default:
              return 'recipes'
          }
        }
        return 'recipes'

      case path.includes('/my-account/delivery') &&
        !path.includes('/[deliveryDate]'):
        return 'choose recipes page'
      case path.includes('/my-account/details'):
        return 'your details page'
      case path.includes('/my-account/referral'):
        return 'referral page'
      case path.includes('/gift-cards'):
        return 'gift card page'
      case path.includes('/healthy-recipes'):
        return 'healthy recipes library page'
      case path.includes('/my-account/subscription'):
        return 'preference page'
      case path.includes('/easy-'):
      case path.includes('/healthy-'):
      case path.includes('/plant-based-'):
      case path.includes('/vegan-recipes'):
        return 'homepage'
      default:
        return 'homepage'
    }
  }, [path, router.query, router.isReady])
}
