import { OrderTypes } from '@mindfulchefuk/analytics'

export const getBasketProductTypes = (
  productsLength: number,
  recipesLength: number
): OrderTypes => {
  if (productsLength && recipesLength) {
    return 'both'
  } else if (recipesLength) {
    return 'recipe'
  } else if (productsLength) {
    return 'ready to go'
  } else {
    return 'none'
  }
}
