import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { getBasketProductTypes } from '@mindfulchefuk/utils/analytics/amplitude/helpers/getBasketProductTypes'

export type InstrumentBasketViewEvent = {
  cartId: string
  cartSize: number
  deliveryAmount: number
  totalBasketAmount: number
  totalReadyToGoAmount: number
  totalRecipeAmount: number
  productsLength: number
  recipesLength: number
  promoCodesApplied?: string
  totalDiscountAmount?: number
  pageSource: TSanitisedBasketViewPageSource
  userIsLoggedIn: boolean
}

export type TSanitisedBasketViewPageSource =
  | 'ready to go category page'
  | 'ready to go product page'
  | 'choose recipe page'

export const instrumentBasketViewEvent = ({
  cartId,
  cartSize,
  deliveryAmount,
  totalBasketAmount,
  totalReadyToGoAmount,
  totalRecipeAmount,
  productsLength,
  recipesLength,
  promoCodesApplied = '',
  totalDiscountAmount = 0,
  pageSource,
  userIsLoggedIn,
}: InstrumentBasketViewEvent) => {
  const rawPageSource = sessionStorage.getItem('referrerPath')
  const { name, data } = analyticsEvents.basketViewed({
    cartId,
    cartSize,
    deliveryAmount,
    promoCodesApplied,
    totalBasketAmount,
    totalDiscountAmount,
    totalReadyToGoAmount,
    totalRecipeAmount,
    productTypesInBasket: getBasketProductTypes(productsLength, recipesLength),
    pageSource: pageSource as PageSource,
    userIsLoggedIn,
  })
  instrument(name, { ...data, rawPageSource })
}
